'use client'
import { Spinner } from '@atnd/ui/loading'
import { Container } from '@mui/material'

export default function Loading() {
	return (
		<Container maxWidth={false}>
			<Spinner className="h-dvh" />
		</Container>
	)
}
