import { twMerge } from 'tailwind-merge'

type SpinnerProps = {
	size?: 'sm' | 'md' | 'lg'
	className?: string
}

export const Spinner = ({ size = 'md', className = '' }: SpinnerProps) => {
	const sizeClass = {
		sm: 'w-8 h-8 border-2',
		md: 'w-12 h-12 border-3',
		lg: 'w-16 h-16 border-4',
	}

	return (
		<div className={twMerge(`flex items-center justify-center`, className)}>
			<div className={twMerge(sizeClass[size], `animate-spin rounded-full border-gray-300 border-s-green-600`)} />
		</div>
	)
}
